<template>
  <div>
    <section class="cover-1 text-center">
      <HeadBlock
        :header-title="headTitle"
        :header-sub-title="headSubTitle"
      ></HeadBlock>
      <h1>404</h1>
      <h2>Lapa nav atrasta</h2>
    </section>

  </div>
</template>
<script>

import HeadBlock from "../common/HeadBlock";

export default {
  data() {
    return {
      headTitle: '',
      headSubTitle: '',
    }
  },
  components: {
    HeadBlock
  }
}
</script>
