<template>
  <div>
    <section class="cover-1 text-center">
      <HeadBlock
        :header-title="$t('pages.home.head.title')"
        :header-sub-title="$t('pages.home.head.subtitle')"
      />

      <AppArrow
        class-name="next-arrows arrows-vertical pointer"
        :scroll-to-id="'why-us-id'"
      />
    </section>

    <section
      id="why-us-id"
      class="content-4 why-us"
    >
      <div class="container">
        <div class="row justify-center mt-5">
          <div class="col-md-6 pl-5 pr-5 text-center">
            <AppImage
              url="projektora-un-ekrana-noma-800x800.jpg"
              alt="Projektoru un ekranu noma"
            />
          </div>
          <div class="col-md-6 text-left">
            <h2 v-text="$t('pages.home.whyUsTitle')" />
            <div class="row">
              <ul>
                <li
                  v-for="(benefit, benefitKey) in benefits"
                  :key="benefitKey"
                  v-text="benefit"
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import HeadBlock from '../common/HeadBlock';
import { helper } from '../../mixins/helper.js';
import AppArrow from '../../components/parts/AppArrow';
import AppImage from '../../components/parts/AppImage';

export default Vue.extend({
  components: {AppImage, AppArrow, HeadBlock },
  mixins: [ helper ],
  data() {
    return {
      // todo move to API to get from CMS
      benefits: [
        'Visi mūsu piedāvātie projektori ir modernākie modeļi ar labākajiem parametriem savā klasē.',
        'Projektoru un ekrānu  komplektiem iespējama piegāde Rīgas centrā, vai bezmaksas saņemšana/nodošana noliktavā.',
        'Elastīgs darba laiks – iespējams (pēc vienošanās) paņemt un nodot projektorus arī brīvdienās un vakaros.',
        'Apmaksājamas tikai tehnikas izmantošanas dienas. Iespējams vienoties par papildus dienām loģistikai.',
        'Projektori, ekrāni pieejami lielos daudzumos. Gandrīz vienmēr varēsim nodrošināt pakalpojumu.',
        'Katrai nākamajai tehnikas izmantošanas dienai var tikt piemērota atlaide.',
      ],
    };
  },
  mounted() {
    //Enable animated background
    //https://code.createjs.com/easeljs-0.7.1.min.js
    //https://cdnjs.cloudflare.com/ajax/libs/gsap/1.18.0/TweenMax.min.js
    //this.addBackgroundScript();
  },
});
</script>
