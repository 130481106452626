//todo get default languages from CMS
import lang from './locales';

export default {
  state: () => ({
    locale: process.env.APP_LOCALE,
    currentLanguage: lang.languages[1],
    lang,
  }),
  mutations: {
    setLanguage(state, language) {
      state.currentLanguage = language;
      state.locale = language;
    },
  },
  getters: {
    currentLanguage: state => state.currentLanguage,
    allLanguages: state => state.lang.languages,
    locale: state => state.locale,
  },
};
