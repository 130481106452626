const defaultState = () => {
  return {
    selectedItems: [],
  };
};
export default {
  state: () => ({
    selectedItems: [],
  }),
  mutations: {
    SET_DISCOUNT_ITEMS(state, items) {
      state.selectedItems.discountSet = items;
    },
    SET_LED_TV_ITEMS(state, items) {
      state.selectedItems.lcd = items;
    },
    SET_PROJECTOR_ITEMS(state, items) {
      state.selectedItems.projector = items;
    },
    SET_PROJECTOR_SCREEN_ITEMS(state, items) {
      state.selectedItems.screen = items;
    },
    SET_EXTRA_ITEMS(state, items) {
      state.selectedItems.extra = items;
    },
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },
  },
  getters: {
    selectedItems: state => state.selectedItems,
    discountSet: state => state.selectedItems.discountSet,
  },
  actions: {
    addDiscountSelectedItems({ commit }, items) {
      commit('RESET_STATE');
      commit('SET_DISCOUNT_ITEMS', items);
    },
    addLedTvSelectedItems({ commit }, items) {
      commit('RESET_STATE');
      commit('SET_LED_TV_ITEMS', items);
    },
    addProjectorSelectedItems({ commit }, items) {
      commit('RESET_STATE');
      commit('SET_PROJECTOR_ITEMS', items);
    },
    addProjectorsScreenSelectedItems({ commit }, items) {
      commit('RESET_STATE');
      commit('SET_PROJECTOR_SCREEN_ITEMS', items);
    },
    addExtraScreenSelectedItems({ commit }, items) {
      commit('SET_PROJECTOR_SCREEN_ITEMS', items);
    },
    addExtraSelectedItems({ commit }, items) {
      commit('SET_EXTRA_ITEMS', items);
    },
  },
};
