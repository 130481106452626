<template>
  <div>
    <l-map
      ref="map"
      class="l__map"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      :attribution="attribution"
      @update:zoom="zoomUpdate"
      @update:center="centerUpdate"
      @update:bounds="boundsUpdate"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />

      <l-marker
        :lat-lng="markerLatLng"
        :name="'my-marker'"
        @click="openPopUp"
      />
    </l-map>
  </div>
</template>

<script>

import { LMap, LTileLayer, LMarker, LTooltip } from 'vue2-leaflet';
import { helper } from '../../mixins/helper';

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  mixins: [ helper ],

  data() {
    return {
      addressGoogleUrl: 'https://maps.app.goo.gl/7D3j1QFNiRtVVvYT7',
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      bounds: null,
      attribution: null,
      zoom: 13,
      center: [56.983860, 24.119660 ],
      mapOptions: {
        zoomSnap: 0.5,
      },
      markerLatLng: [56.983860, 24.119660],
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.zoom = zoom;
    },
    centerUpdate(center) {
      this.center = center;
    },
    boundsUpdate(bounds) {
      this.bounds = bounds;
    },
    openPopUp() {
      let popUp = this.createPopUp();
      this.$refs.map.mapObject.openPopup(popUp);
    },
    createPopUp() {
      return L.popup({keepInView: true})
        .setLatLng(this.markerLatLng)
        .setContent(`
              <div class="map-popup__content">
                  <p class="map-popup__title"><img src="../../../dist/assets/images/projektoru-noma-logo.jpg" alt="Projektoru noma logo"> ${'Projektoru-noma.lv'}</p>
                  <p class="map-popup__address">${this.contacts.address}</p>
                 <a class="link-get-direction"
                  href="${this.addressGoogleUrl}"
                  target="_blank">${this.$t('common.footer.get_directions')}</a>
              </div>
     `);
    },
  },
};
</script>
<style lang="scss" scoped>
.l__map {
  height: 100%;
}
</style>
