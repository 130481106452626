export const helper = {
  data() {
    return {
      currency: 'EUR',
      currencySymbol: '€',
      contacts: {
        phone: '+371 29600561',
        address: 'Ganību dambis 26, Rīga',
        email: 'info@projektoru-noma.lv',
        addressGoogleUrl: 'https://maps.app.goo.gl/7D3j1QFNiRtVVvYT7',
        instaLink: 'https://www.instagram.com/skanagaisma.lv',
        facebookLink: 'https://www.facebook.com/projektorunoma.lv',
      },
      settings: {
        consentsEnabled: false,
      },
      cityList: [
        'Rīga',
      ],
    };
  },
  created() {
    //
  },
  methods: {
    addBackgroundScript() {
      let recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute('src', 'dist/assets/js/background.js');
      document.body.appendChild(recaptchaScript);
    },
    formatImageUrlPath(imgName) {
      return 'dist/assets/images/' + imgName;
    },

    scrollToElement(el) {
      document
        .getElementById(el)
        .scrollIntoView({
          behavior: 'smooth',
        });
    },
    formatPrice(price) {
      return price + ' ' + this.currencySymbol;
    },
    applyButtonState(itemsSelected) {
      return itemsSelected.length > 0;
    },
    enter: function (el, done) {
      //https://vuejs.org/v2/guide/transitions.html
      done();
      this.scrollToElement(el.id);
    },
    getSelectedItemsSum($itemsSelected) {
      let sum = 0;
      let prices = [];

      //get all prices from items
      Object.keys($itemsSelected).forEach(key => {
        if (Array.isArray($itemsSelected[key])) {
          $itemsSelected[key].filter(function (value) {
            prices.push(value.price);
          });
        }
      });

      //sum all prices
      if (typeof prices != 'undefined' && prices.length) {
        sum = prices.reduce((a, b) => a + b);
      }
      return sum;
    },


  },
  computed: {
    multipleSelectIsEnabled() {
      return  process.env.APP_SALES_MULTIPLE_SELECT === true;
    },
    formatCallablePhoneNumber() {
      return this.contacts.phone.replace(/ /g, '');
    },
  },

};
