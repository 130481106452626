//todo get from CMS & get delivery/assembly by city
export default {
  state: () => ({
    serviceList: {
      delivery: [
        {
          name: 'delivery',
          title: 'Piegāde/savākšana',
          description: 'Nepieciešama piegāde',
          price: 40,
          city: 'Rīga',
        },
      ],
      assembly: [
        {
          name: 'assembly',
          title: 'Salikšana (montāža/demontāža)',
          description: 'Nepieciešama montāža/demontāža',
          price: 40,
          city: 'Rīga',
        },
      ],
    },
  }),
  getters: {
    serviceList: state => state.serviceList,
  },
};
