//todo get from CMS
export default {
  projectorsList: [
    {
      name: 'projector_1',
      title: 'Budžeta klases projektos',
      description: 'Pilnībā aptumšotām telpām',
      img: 'projectors/web_projektoru-noma-proj-aun-small.png',
      price: 26,
      shortInfo: [
        '2m platai projekcijai nepieciešama 3m distance',
        'piemērots nelieliem mājas kino vakariem telpās',
        'HDMI kabelis komplektā (citi pēc pieprasījuma).',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
    {
      name: 'projector_2',
      title: 'Vidējās klases projektors',
      description: 'Piemērots nelielai prezentācijai birojā',
      img: 'projectors/web_projektoru-noma-proj-acer--small.png',
      price: 30,
      shortInfo: [
        'piemērots mājas vai dārza kino pēc saulrieta',
        '2m platai projekcijai nepieciešama 4m distance',
        'XGA izšķirtspēja. Gaišums 3200-3600lm',
        'HDMI kabelis komplektā (citi pēc pieprasījuma).',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
    {
      name: 'projector_3',
      title: 'Profesionālais projektors',
      description: 'Piemērots vidējām un lielām prezentācijām',
      img: 'projectors/web_projektoru-noma-proj-dell-fullhd-small.png',
      price: 55,
      shortInfo: [
        'piemērots nelieliem brīvdabas kino seansiem pēc saulrieta',
        'FullHD izšķirtspēja. Gaišums: 5000lm',
        '2m platai projekcijai nepieciešama 3,5-4,5m distance no ekrāna',
        'HDMI kabelis komplektā (citi pēc pieprasījuma).',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
    {
      name: 'projector_4',
      title: 'Tuvās distances projektors',
      description: 'Telpām, kurās nav iespējas novietot projektoru pietiekamā distancē no ekrāna',
      img: 'projectors/web_projektoru-noma-proj-wide-small.png',
      price: 39,
      shortInfo: [
        '2m platais projekcijai pietiek ar 1m distanci no ekrāna',
        'HDMI kabelis komplektā (citi pēc pieprasījuma).',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
  ],
  projectorsScreenList: [
    {
      name: 'screen_1',
      title: '1.5m plats ekrāns uz statīva',
      description: 'Piemērots prezentācijām vai mājas kino',
      img: 'projectors_screen/web_projektoru-noma-screen-15-small.png',
      price: 12,
      shortInfo: [
        'viegla uzstādīšana',
        'komplektā statīvs',
        'transportējams ar gandrīz jebkuru auto',
        'korpuss baltā krāsā',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
    {
      name: 'screen_2',
      title: '2m plats ekrāns uz statīva',
      description: 'Piemērots prezentācijām vai mājas kino',
      img: 'projectors_screen/web_projektoru-noma-screen-20-small.png',
      price: 15,
      shortInfo: [
        'viegla uzstādīšana',
        'komplektā statīvs',
        'transportējams ar gandrīz jebkuru auto',
        'korpuss baltā vai melnā krāsā',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
    {
      name: 'screen_3',
      title: '2.4m plats ekrāns uz statīva',
      description: 'Piemērots prezentācijām vai mājas kino',
      img: 'projectors_screen/web_projektoru-noma-screen-24-small.png',
      price: 25,
      shortInfo: [
        'viegla uzstādīšana',
        'komplektā statīvs',
        'transportējams ar gandrīz jebkuru auto',
        'korpuss melnā krāsā',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
    {
      name: 'screen_4',
      title: 'Piepūšamais projektora ekrāns',
      description: 'Piemērots brīvdabas kino vakariem dārzā',
      img: 'projectors_screen/web_projektoru-noma-screen-outdoor-small.png',
      price: 40,
      shortInfo: [
        'ekrāna redzamā daļa 4,5-5m plata',
        'ekrāna piepūšanai nepieciešams tikai 1-22min',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
    {
      name: 'screen_none',
      title: '',
      img: '',
      price: null,
      keyPoints: [],
    },
  ],
  tvList: [
    {
      name: 'monitor_2',
      title: 'UHD 55” LED smart TV ekrāns uz statīva',
      description: 'pielietojums: Izstādēm, karaoke, mazām prezentācijām.',
      img: 'LCD/web_projektoru-noma-tv-55-small.png',
      price: 49,
      shortInfo: [
        'UHD 4k izšķirtspēja',
        'trīskāja tipa statīvs',
        'iespēja atskaņot video vai bildes no USB Flash atmiņas.',
        'HDMI savienojums ar datoru',
        'visas Smart TV funkcijas',
        'pieejami lielā skaitā',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
    {
      name: 'monitor_3',
      title: 'UHD 65” LED smart TV ekrāns uz statīva',
      description: 'pielietojums: Prezentācijām, pasākumiem',
      img: 'LCD/web_projektoru-noma-tv-65-small.png',
      price: 79,
      shortInfo: [
        'UHD 4k izšķirtspēja',
        'trīskāja tipa statīvs',
        'iespēja atskaņot video vai bildes no USB Flash atmiņas',
        'HDMI savienojums ar datoru',
        'visas Smart TV funkcijas',
        'pieejami vairāki',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
    {
      name: 'monitor_4',
      title: 'UHD 75” LED smart TV ekrāns uz statīva',
      description: 'pielietojums: Prezentācijām, pasākumiem.',
      img: 'LCD/web_projektoru-noma-tv-75-small.png',
      price: 120,
      shortInfo: [
        'UHD 4k izšķirtspēja',
        'stabils 4 atbalsta punktu tipa statīvs. Izmantojams arī ārpus telpām',
        'iespēja atskaņot video vai bildes no USB Flash atmiņas',
        'HDMI savienojums ar datoru',
        'visas Smart TV funkcijas',
        'pieejami vairāki',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
    {
      name: 'monitor_5',
      title: 'UHD 85” LED smart TV ekrāns uz statīva',
      description: 'pielietojums: Prezentācijām, pasākumiem.',
      img: 'LCD/web_projektoru-noma-tv-85-small.png',
      price: 240,
      shortInfo: [
        'UHD 4k izšķirtspēja',
        'stabils 4 atbalsta punktu tipa statīvs. Izmantojams arī ārpus telpām',
        'iespēja atskaņot video vai bildes no USB Flash atmiņas',
        'HDMI savienojums ar datoru',
        'visas Smart TV funkcijas',
        'pieejami vairāki',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
  ],
  projectorsExtraItemsList: [
    {
      name: 'extra_1',
      title: 'Prezentācijas pārslēdzējs – klikeris',
      description: '',
      img: 'extra/logitec-clicker-noma-small.png',
      price: 5,
      shortInfo: [
        'Ļauj pārslēgt prezentācijas slaidus, neatrodoties pie datora',
        'iebūvēts lāzers norādēm uz ekrāna',
        'viegli pieslēdzams datora USB portam (Līdzīgi, kā bezvadu pele)',
        'darbības attālums līdz 10m',
      ],
      modalEnabled: false,
      keyPoints: 'Ļauj pārslēgt prezentācijas slaidus, neatrodoties pie datora',
      imgGallery: [],
    },
    {
      name: 'extra_2',
      title: 'Projektora galdiņš',
      description: '',
      img: 'extra/extra_3-small.png',
      price: 5,
      shortInfo: [
        'Projektors jānovieto tieši pretī ekrānam. Projektora galdiņš ir risinājums, lai projektoru varētu novietot tieši tur, kur nepieciešams',
        'regulējams augstums 0.5-1m',
        'ritenīši ērtai pārvietošanai telpā.',
      ],
      modalEnabled: false,
      keyPoints: 'Projektora galdiņš',
      imgGallery: [],
    },
    {
      name: 'extra_3',
      title: 'Skaļruņi',
      description: '',
      img: 'extra/nelielu-skalrunu-noma-small.png',
      price: 10,
      shortInfo: [
        'Prezentācijas audio pavadījums būs iespaidīgāks un dzirdamāks auditorijai, ja izmatosiet papildus skaļruņus ',
        'līdz 30-50m2 telpai',
        'AUX kabelis pieslēgšanai datoram',
      ],
      modalEnabled: false,
      keyPoints: 'Skaļruņi',
      imgGallery: [],
    },
    {
      name: 'extra_4',
      title: 'Media player (failu atskaņotājs)',
      description: '',
      img: 'extra/extra_4-small.png',
      price: 8,
      shortInfo: [
        'Atskaņo video vai rāda bildes, kad dators nav pieejams',
        'piemērots izstādēm, kur nepieciešams video atskaņot “cilpā”, bez liekas ielādes informācijas uz ekrāna',
        'var pieslēgt vienlaicīgi 2 ekrānus vai projektorus',
      ],
      modalEnabled: false,
      keyPoints: 'Failu atskaņotājs',
      imgGallery: [],
    },
    {
      name: 'extra_none',
      title: '',
      img: '',
      price: null,
      keyPoints: [],
    },
  ],
  tvExtraItemsList: [
    {
      name: 'extra_1',
      title: 'Prezentācijas pārslēdzējs – klikeris',
      description: '',
      img: 'extra/logitec-clicker-noma-small.png',
      price: 5,
      shortInfo: [
        'Ļauj pārslēgt prezentācijas slaidus, neatrodoties pie datora',
        'iebūvēts lāzers norādēm uz ekrāna',
        'viegli pieslēdzams datora USB portam (Līdzīgi, kā bezvadu pele)',
        'darbības attālums līdz 10m',
      ],
      modalEnabled: false,
      keyPoints: 'Ļauj pārslēgt prezentācijas slaidus, neatrodoties pie datora',
      imgGallery: [],
    },
    {
      name: 'extra_3',
      title: 'Skaļruņi',
      description: '',
      img: 'extra/nelielu-skalrunu-noma-small.png',
      price: 10,
      shortInfo: [
        'Prezentācijas audio pavadījums būs iespaidīgāks un dzirdamāks auditorijai, ja izmatosiet papildus skaļruņus ',
        'līdz 30-50m2 telpai',
        'AUX kabelis pieslēgšanai datoram',
      ],
      modalEnabled: false,
      keyPoints: 'Skaļruņi',
      imgGallery: [],
    },
    {
      name: 'extra_4',
      title: 'Media player (failu atskaņotājs)',
      description: '',
      img: 'extra/extra_4-small.png',
      price: 8,
      shortInfo: [
        'Atskaņo video vai rāda bildes, kad dators nav pieejams',
        'piemērots izstādēm, kur nepieciešams video atskaņot “cilpā”, bez liekas ielādes informācijas uz ekrāna',
        'var pieslēgt vienlaicīgi 2 ekrānus vai projektorus',
      ],
      modalEnabled: false,
      keyPoints: 'Failu atskaņotājs',
      imgGallery: [],
    },
    {
      name: 'extra_none',
      title: '',
      img: '',
      price: null,
      keyPoints: [],
    },
  ],
  discountSetList: [
    {
      name: 'set_1',
      title: '2 m ekrāna un projektora komplekts',
      description: 'Piemērots nelielām prezentācijām vai mājas kino vakariem.',
      img: 'komplekti/2m-ekrans-standarta-projektora-komplekta-noma-small.png',
      price: 39,
      shortInfo: [
        '2 m plats ekrāns uz statīva',
        'vidējās klases projektors (XGA, 3200-3600lm), HDMI kabelis savienošanai ar datoru',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
    {
      name: 'set_2',
      title: 'Piepūšamā ekrāna un projektora komplekts',
      description: 'Piemērots brīvdabas kino vakariem dārzā.',
      img: 'komplekti/piepusamais-ekrans-uzlabota-projektora-komplekta-noma-small.png',
      price: 109,
      shortInfo: [
        'Piepūšamais ekrāns (4,5-5m plata redzamā daļa)',
        'uzlabotais projektors (FullHD, 5000lm)',
        'jaudīga skaņa (300W), noderīga arī ballītei pēc kino.',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
    {
      name: 'set_3',
      title: 'Divu 55” ekrānu uz statīva komplekts',
      subTitle: 'Piemērots brīvdabas kino vakariem dārzā.',
      description: 'Piemērots nelielām prezentācijām, izstādēm.',
      img: 'komplekti/divu-55-colu-ekranu-noma-small.png',
      price: 99,
      shortInfo: [
        'Divi 55” ekrāni uz statīva',
        '4k UHD izšķirtspēja',
        'visas Smart TV funkcijas',
        'HDMI Spliteris (sadalītājs) un kabeļi nepieciešamajā garumā. Abi ekrāni var rādīt saturu no viena avota.',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
    {
      name: 'set_4',
      title: 'Prezentācijas komplekts ar mikrofonu',
      description: 'Piemērots nelielām prezentācijām auditorijās līdz 80-100 m2.',
      img: 'komplekti/prezentacijas-pakas-noma-small.png',
      price: 66,
      shortInfo: [
        '2 m plats ekrāns uz statīva',
        'vidējās klases projektors (XGA, 3200-3600lm), HDMI kabelis savienošanai ar datoru',
        'jaudīga skanda (tumba) ar bezvadu mikrofonu. Iespēja prezentācijas pauzēs atskaņot mūziku, izmantojot bluetooth vai AUX kabeli.',
      ],
      modalEnabled: false,
      keyPoints: [],
      imgGallery: [],
    },
  ],
};
