<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-dark navbar-custom">
      <div class="container">
        <router-link
          to="/"
          class="navbar-brand"
        >
          <div
            class="like-h1"
            v-text="$t('pages.home.title')"
          />
        </router-link>

        <div class="mobile-only ">
          <a
            :href="'tel:' + formatCallablePhoneNumber"
            class="deployform"
          >
            <i
              class="fa fa-phone icon"
              aria-hidden="true"
            />
          </a>
        </div>
        <HeaderMenu />
      </div>
    </nav>
    <div class="cover-container">
      <div class="cover-inner container">
        <h1 class="jumbotron-heading">
          <strong v-text="headerTitle" />
        </h1>
        <p
          class="lead"
          v-text="headerSubTitle"
        />
        <HeaderPrimaryMenu v-if="menuItems" />
      </div>
    </div>
    <slot />
  </header>
</template>

<script>
import { helper } from '../../mixins/helper.js';
import HeaderMenu from '../../components/menu/HeaderMenu';
import HeaderPrimaryMenu from '../../components/menu/HeaderPrimaryMenu';

export default {
  components: {HeaderPrimaryMenu, HeaderMenu },
  mixins: [ helper ],
  props: {
    headerTitle: {
      type: String,
      required: false,
      default: '',
    },
    headerSubTitle: {
      type: String,
      required: false,
      default: '',
    },
    menuItems: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cover-container {
  align-items: center;
}
</style>
