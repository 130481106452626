export default {
  languages: [
    {
      locale: 'en',
      long: 'English',
    },
    {
      locale: 'lv',
      long: 'Latvian',
    },
  ],
};
