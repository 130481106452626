<template>
  <picture>
    <source
      :srcset="fullUrl"
      type="image/jpeg"
    >
    <img
      class="mb-4 img-fluid"
      :src="fullUrl"
      :alt="alt"
    >
  </picture>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
      default: '',
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    fullUrl() {
      return '../../../dist/assets/images/' + this.url;
    },
  },
};
</script>
