<template>
  <div class="form-page">
    <section
      v-if="!loading"
      class="cover-1 text-center"
    >
      <HeadBlock
        :header-title="$t('pages.tvPage.head.title')"
        :header-sub-title="$t('pages.tvPage.head.subtitle')"
      />
    </section>
    <transition name="fade">
      <main v-show="!loading">
        <div
          id="el-2__form-start"
          class="main-form"
        >
          <div class="container">
            <div class="main-form__title">
              <span
                class="like-h2"
                v-text="$t('pages.tvPage.productTitle')"
              />
            </div>

            <div class="row">
              <div
                v-for="(monitor, key) in ledTvList"
                :key="key"
                class="main-form__item"
                :class="{'active-item btn btn-primary btn-lg mb-2 mr-2 ml-2': isActive(monitor)}"
              >
                <label>
                  <input
                    :id="monitor.name"
                    v-model="monitorsSelected"
                    type="checkbox"
                    :value="monitor.name"
                    class="hidden"
                    @input="updateSelected"
                  >
                  <div class="main-form__item__content">
                    <span
                      class="main-form__item__title"
                      v-text="monitor.title"
                    />
                    <div class="main-form__item__image">
                      <img
                        :src="getImagePath(monitor.img)"
                        :alt="monitor.title"
                      >
                    </div>
                    <div class="main-form__item__content__text">
                      <p
                        class="description"
                        v-text="monitor.description"
                      />
                      <ul>
                        <li
                          v-for="(shortInfo, shortInfoIndex) of monitor.shortInfo"
                          :key="shortInfoIndex"
                          v-text="shortInfo"
                        />
                      </ul>
                    </div>
                    <span
                      v-if="!monitorsSelected.includes(monitor.name)"
                      class="main-form__item__content__btn btn btn-primary"
                      v-text="$t('common.select')"
                    />
                  </div>
                </label>

                <div v-if="monitor.modalEnabled">
                  <button
                    class="modalOpenBtn"
                    @click="openModal(key + '-' + monitor.name)"
                  >
                    <span class="info-icon" />
                    {{ $t('common.more_info') }}
                  </button>
                  <vodal
                    :show="modalIsVisible(key + '-' + monitor.name)"
                    animation="door"
                    :width="800"
                    :height="600"
                    @hide="closeModal(key + '-' + monitor.name)"
                  >
                    <div class="container modal-text">
                      <h2 v-text="monitor.title" />
                      <div class="col-md-8-center">
                        <b-carousel
                          id="carousel-no-animation"
                          style="text-shadow: 0 0 2px #000"
                          no-animation
                          indicators
                        >
                          <span
                            v-for="(imageSrc, imageSrcIndex) of monitor.imgGallery"
                            :key="imageSrcIndex"
                          >
                            <b-carousel-slide
                              :img-src="formatImageUrlPath(imageSrc)"
                            />
                          </span>
                        </b-carousel>
                      </div>
                      <ul>
                        <li
                          v-for="(keyPoint, keyPointIndex) of monitor.keyPoints"
                          :key="keyPointIndex"
                          v-text="keyPoint"
                        />
                      </ul>
                    </div>
                  </vodal>
                </div>
                <div
                  v-if="monitor.price"
                  class="price"
                >
                  {{ formatPrice(monitor.price) }}
                  <span v-text="$t('common.without_wat')" />
                </div>
              </div>
            </div>

            <transition
              name="fade"
              mode="out-in"
              @enter="enter"
            >
              <ExtraSelect
                v-show="monitorsSelected.length"
                :extra-items-list="tvExtraList"
              />
            </transition>
          </div>
        </div>
      </main>
    </transition>
  </div>
</template>

<script>
import ExtraSelect from '../../components/ExtraSelect';
import Vodal from 'vodal';
import HeadBlock from '../common/HeadBlock';
import { helper } from '../../mixins/helper.js';
import { mapGetters } from 'vuex';

export default {
  components: { ExtraSelect, HeadBlock, Vodal},
  mixins: [ helper ],
  data() {
    return {
      loading: true,
      modal: [],
      monitorsSelected: [],
      formPreviousId: 'el-2__form-start',
    };
  },
  computed: {
    ...mapGetters([
      'ledTvList',
      'tvExtraList',
    ]),
  },
  watch: {
    monitorsSelected: function () {
      let list = [];
      for (let value of this.monitorsSelected) {
        this.ledTvList.filter(obj => {
          if (obj.name === value) {
            return list.push(obj);
          }
          return false;
        });
      }
      this.$store.dispatch('addLedTvSelectedItems', list);
    },
  },
  beforeMount() {
    // this.addBackgroundScript();
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    isActive(item) {
      return this.monitorsSelected.includes(item.name);
    },
    getImagePath(img) {
      return this.formatImageUrlPath(img);
    },
    enter: function (el, done) {
      //https://vuejs.org/v2/guide/transitions.html
      done();
      this.scrollToElement(el.id);
    },
    openModal(key) {
      this.modal.push(key);
    },
    modalIsVisible(key) {
      return this.modal.includes(key);
    },
    closeModal(key) {
      this.modal.splice(this.modal.indexOf(key), 1);
    },
    updateSelected(e) {
      if (this.multipleSelectIsEnabled) {
        return;
      }
      this.monitorsSelected = [e.target.value];
    },
  },
};
</script>
