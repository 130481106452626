<template>
  <div class="success">
    <section
      class="cover-1 text-center"
      :show="loading"
    >
      <header>
        <nav class="navbar navbar-expand-lg navbar-dark navbar-custom">
          <div class="container">
            <router-link
              to="/"
              class="navbar-brand"
            >
              <div
                class="like-h1"
                v-text="$t('pages.success.title')"
              />
            </router-link>
          </div>
        </nav>
      </header>
      <div class="success__page container">
        <div class="success__page__contacts">
          <h3 v-text="$t('pages.success.success_message')" />

          <h4
            class="pt-2"
            v-text="$t('common.phone')"
          />
          <p>
            <a
              :href="'tel:' + formatCallablePhoneNumber"
              v-text="contacts.phone"
            />
          </p>
          <h4
            class="pt-2"
            v-text="$t('pages.success.our_coordinates')"
          />
          <p>
            <a
              :href="contacts.addressGoogleUrl"
              target="_blank"
              v-text="contacts.address"
            />
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

  import { helper } from '../../mixins/helper.js';
  export default {
    mixins: [ helper ],
    data() {
      return {
        loading: false,
      };
    },
  };
</script>

<style type="scss" scoped>
  .success__page {
    height: 300px;
    display: flex;
    justify-content: center;
  }
  .success__page a {
    color: #2b2d34;
    font-weight: 600;
  }
  .success__page__contacts {
    margin-top: auto;
  }
  .cover-1 {
    min-height: 30rem;
  }
</style>
