import Vue from 'vue';
import Vuex from 'vuex';
import vuexI18n from 'vuex-i18n';

import translationsEn from '../lang/en';
import translationsLv from '../lang/lv';
import productStore from './productStore';
import selectedItemStore from './selectedItemStore';
import serviceList from './serviceList';
import lang from './lang';

Vue.use(Vuex);

//init store
const store = new Vuex.Store({
  modules: {
    lang,
    productStore,
    selectedItemStore,
    serviceList,
  },
});

Vue.use(vuexI18n.plugin, store);

Vue.i18n.add('en', translationsEn);
Vue.i18n.add('lv', translationsLv);
Vue.i18n.set(process.env.APP_LOCALE);

export default store;
