<template>
  <footer
    id="footer-section"
    class="contact-1"
  >
    <div class="container p-4">
      <div class="row">
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5
            class="text-uppercase"
            v-text="$t('common.footer.products')"
          />
          <FooterMenu />
        </div>

        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <a
            href="/kontakti"
            target="_self"
          >
            <h5
              class="text-uppercase"
              v-text="$t('common.footer.contacts')"
            />
          </a>
          <AppContacts :show-social-networks="true" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { helper } from '../../mixins/helper.js';
import FooterMenu from '../../components/menu/FooterMenu';
import AppContacts from '../../components/AppContacts';

export default {
  components: {AppContacts, FooterMenu },
  mixins: [ helper ],
  computed: {
    formatCallablePhoneNumber() {
      return this.contacts.phone.replace(/\D/g, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>

<style lang="scss" scoped>
.contacts {
  align-items: flex-start;
}
</style>
