<template>
  <validation-provider
    v-slot="validationContext"
    :name="field"
    :rules="rules"
  >
    <b-form-group :id="field + '-group'">
      <b-form-datepicker
        :id="field"
        v-model="value"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        :name="field"
        :state="getValidationState(validationContext)"
        :locale="locale"
        :placeholder="placeholder"
        :min="minDate"
        :selected-variant="'primary'"
        class="mb-2"
        size="sm"
        :label-help="''"
        :label-no-date-selected="''"
        :label-current-month="''"
        :hide-header="true"
        :required="true"
        :reset-value="value"
        @input="inputEvent"
      />
      <b-form-invalid-feedback v-text="validationMessage" />
    </b-form-group>
  </validation-provider>
</template>

<script>

export default {
  props: {
    field: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
    validationMessage: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    minDate: {
      type: Date,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      value: '',
      locale: this.$store.getters.locale,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    inputEvent(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
