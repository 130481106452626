<template>
  <div class="form-page">
    <section
      v-if="!loading"
      class="cover-1 text-center"
    >
      <HeadBlock
        :header-title="$t('pages.projectors.head.title')"
        :header-sub-title="$t('pages.projectors.head.subtitle')"
      />
    </section>

    <transition name="fade">
      <main v-show="!loading">
        <div
          id="el-1__form-start"
          class="main-form"
        >
          <div class="container">
            <div class="main-form__title">
              <span
                class="like-h2"
                v-text="$t('pages.projectors.productTitle')"
              />
            </div>

            <div
              id="el-1__first-step"
              class="row"
            >
              <div
                v-for="(projector, key) in projectorsList"
                :key="key"
                class="main-form__item"
                :class="{'active-item btn btn-primary btn-lg mb-2 mr-2 ml-2': isActive(projector)}"
              >
                <label>
                  <input
                    :id="projector.name"
                    v-model="projectorsSelected"
                    type="checkbox"
                    :value="projector.name"
                    class="hidden"
                    @input="updateSelected"
                  >
                  <div class="main-form__item__content">
                    <span
                      class="main-form__item__title"
                      v-text="projector.title"
                    />
                    <div class="main-form__item__image">
                      <img
                        :src="getImagePath(projector.img)"
                        :alt="projector.title"
                      >
                    </div>
                    <div class="main-form__item__content__text">
                      <p
                        class="description"
                        v-text="projector.description"
                      />
                      <ul>
                        <li
                          v-for="(shortInfo, shortInfoIndex) of projector.shortInfo"
                          :key="shortInfoIndex"
                          v-text="shortInfo"
                        />
                      </ul>
                    </div>
                    <span
                      v-if="!projectorsSelected.includes(projector.name)"
                      class="main-form__item__content__btn btn btn-primary"
                      v-text="$t('common.select')"
                    />
                  </div>
                </label>

                <div v-if="projector.modalEnabled">
                  <button
                    class="modalOpenBtn"
                    @click="openModal(key + '-' + projector.name)"
                  >
                    <span class="info-icon" />
                    {{ $t('common.more_info') }}
                  </button>
                  <vodal
                    :show="modalIsVisible(key + '-' + projector.name)"
                    animation="door"
                    :width="800"
                    :height="600"
                    @hide="closeModal(key + '-' + projector.name)"
                  >
                    <div class="container modal-text">
                      <h2 v-text="projector.title" />
                      <div class="col-md-8-center">
                        <b-carousel
                          id="carousel-no-animation"
                          style="text-shadow: 0 0 2px #000"
                          no-animation
                          indicators
                        >
                          <span
                            v-for="(imageSrc, imageSrcIndex) of projector.imgGallery"
                            :key="imageSrcIndex"
                          >
                            <b-carousel-slide
                              :img-src="getImagePath(imageSrc)"
                            />
                          </span>
                        </b-carousel>
                      </div>
                      <ul>
                        <li
                          v-for="(keyPoint, keyPointIndex) of projector.keyPoints"
                          :key="keyPointIndex"
                          v-text="keyPoint"
                        />
                      </ul>
                    </div>
                  </vodal>
                </div>
                <div
                  v-if="projector.price"
                  class="price"
                >
                  {{ formatPrice(projector.price) }}
                  <span v-text="$t('common.without_wat')" />
                </div>
              </div>
            </div>
          </div>

          <transition
            name="fade"
            mode="out-in"
            @enter="enter"
          >
            <ProjectorsScreen
              v-show="projectorsSelected.length"
            />
          </transition>
        </div>
      </main>
    </transition>
  </div>
</template>

<script>
  import Vodal from 'vodal';
  import ProjectorsScreen from '../../components/ProjectorsScreenSelect';
  import HeadBlock from '../common/HeadBlock';
  import { helper } from '../../mixins/helper.js';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      ProjectorsScreen,
      HeadBlock,
      Vodal,
    },
    mixins: [ helper ],
    data() {
      return {
        loading: true,
        modal: [],
        formPreviousId: 'el-1__form-start',
        projectorsSelected: [],
      };
    },
    computed: {
      ...mapGetters([
        'projectorsList',
      ]),
    },
    watch: {
      projectorsSelected: function () {
        let list = [];

        for (let value of this.projectorsSelected) {
          this.projectorsList.filter(obj => {
            if (obj.name === value) {
              return list.push(obj);
            }
            return false;
          });
        }
        this.$store.dispatch('addProjectorSelectedItems', list);
      },
    },
    mounted() {
      this.loading = false;
    },
    methods: {
      isActive(item) {
        return this.projectorsSelected.includes(item.name);
      },
      enter: function (el, done) {
        //https://vuejs.org/v2/guide/transitions.html
        done();
        this.scrollToElement(el.id);
      },
      getImagePath(img) {
        return this.formatImageUrlPath(img);
      },
      openModal(key) {
        this.modal.push(key);
      },
      modalIsVisible(key) {
        return this.modal.includes(key);
      },
      closeModal(key) {
        this.modal.splice(this.modal.indexOf(key), 1);
      },
      updateSelected(e) {
        if (this.multipleSelectIsEnabled) {
          return;
        }
        this.projectorsSelected = [e.target.value];
      },
    },
  };
</script>
