<template>
  <div class="form-page">
    <section
      v-if="!loading"
      class="cover-1 text-center"
    >
      <HeadBlock
        :header-title="$t('pages.projectorsScreen.head.title')"
        :header-sub-title="$t('pages.projectorsScreen.head.subtitle')"
      />
    </section>

    <transition name="fade">
      <main v-show="!loading">
        <div class="main-form">
          <div class="container">
            <div class="main-form__title">
              <span
                class="like-h2"
                v-text="$t('pages.projectorsScreen.productTitle')"
              />
            </div>

            <div class="row second-step">
              <div
                v-for="(screen, key) in screenList"
                :key="key"
                class="main-form__item col-md-5ths col-md-4"
                :class="{'active-item btn btn-primary btn-lg mb-2 mr-2 ml-2': isActive(screen) }"
              >
                <label>
                  <input
                    :id="screen.name"
                    v-model="screensSelected"
                    type="checkbox"
                    :value="screen.name"
                    class="hidden"
                    @input="updateSelected"
                  >
                  <div
                    v-if="screen.name !== none"
                    class="main-form__item__content"
                  >
                    <span
                      class="main-form__item__title"
                      v-text="screen.title"
                    />
                    <div class="main-form__item__image">
                      <img
                        :src="getImagePath(screen.img)"
                        :alt="screen.title"
                      >
                    </div>
                    <div class="main-form__item__content__text">
                      <p
                        class="description"
                        v-text="screen.description"
                      />
                      <ul>
                        <li
                          v-for="(shortInfo, shortInfoIndex) of screen.shortInfo"
                          :key="shortInfoIndex"
                          v-text="shortInfo"
                        />
                      </ul>
                    </div>
                    <span
                      v-if="!isActive(screen)"
                      class="main-form__item__content__btn btn btn-primary"
                      v-text="$t('common.select')"
                    />
                  </div>
                </label>

                <div
                  v-if="screen.price"
                  class="price"
                >
                  {{ formatPrice(screen.price) }}
                  <span v-text="$t('common.without_wat')" />
                </div>
              </div>
            </div>

            <transition
              name="fade"
              mode="out-in"
              @enter="enter"
            >
              <ApplyForm
                v-show="showApplyButton"
              />
            </transition>
          </div>
        </div>
      </main>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeadBlock from '../common/HeadBlock';
import ApplyForm from '../../components/parts/ApplyForm';
import {helper} from '../../mixins/helper.js';

export default {
  components: { HeadBlock, ApplyForm },
  mixins: [ helper ],
  data() {
    return {
      loading: true,
      none: 'screen_none',
      screensSelected: [],
      showApplyButton: false,
      modal: [],
      formPreviousId: 'el-1__second-step',
    };
  },
  computed: {
    ...mapGetters([
      'screenList',
    ]),
  },
  watch: {
    screensSelected: function () {
      let list = [];

      if (!this.applyButtonState(this.screensSelected)) {
        return this.showApplyButton = false;
      }

      for (let value of this.screensSelected) {
        this.screenList.filter(obj => {
          if (obj.name === value) {
            return list.push(obj);
          }
          return false;
        });
      }
      this.$store.dispatch('addProjectorsScreenSelectedItems', list);
      return this.showApplyButton = true;
    },
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    getImagePath(img) {
      return this.formatImageUrlPath(img);
    },
    isActive(screen) {
      return this.screensSelected.includes(screen.name);
    },
    noneIsSelected() {
      return this.screensSelected.includes(this.none);
    },
    enter: function (el, done) {
      //https://vuejs.org/v2/guide/transitions.html
      done();
      this.scrollToElement(el.id);
    },
    updateSelected(e) {
      if (this.multipleSelectIsEnabled) {
        return;
      }
      this.screensSelected = [e.target.value];
    },
  },
};
</script>
