import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './store/store';

// styles import
import './assets/sass/style.scss';
// vendor styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'bootstrap/dist/css/bootstrap-reboot.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vodal/common.css';
import 'vodal/rotate.css';
import 'leaflet/dist/leaflet.css';

import router from './router/route';
//init helpers tools
import VueScrollTo from 'vue-scrollto';
import VueLazyLoad from 'vue-lazyload';
// init BootstrapVue
import { ValidationObserver, ValidationProvider, extend, localize} from 'vee-validate';
import lv from 'vee-validate/dist/locale/lv.json'; //todo validate by lang
import * as rules from 'vee-validate/dist/rules';
import BootstrapVue from 'bootstrap-vue';
// init Maps
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('lv', lv); // lang for vee-validation
// Install VeeValidate
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.component('LMap', LMap);
Vue.component('LTileLayer', LTileLayer);
Vue.component('LMarker', LMarker);

Vue.use(BootstrapVue);
Vue.use(VueLazyLoad);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

Vue.config.devtools = true;

// todo move to component
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

// SEO Meta info add to router
router.beforeEach((to, from, next) => {

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  }

  Array
    .from(document.querySelectorAll('[data-vue-router-controlled]'))
    .map(el => el.parentNode.removeChild(el));

  if (!nearestWithMeta) {
    return next();
  }

  // set meta tags
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    return tag;
  })
    .forEach(tag => document.head.appendChild(tag));

  next();
});

new Vue({
  router: router,
  store,
  render: h => h(App),
}).$mount('#app');
