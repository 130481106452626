<template>
  <div class="col item-list">
    {{ $t('form.products') }}
    <div
      v-for="(projector, projectorKey) in itemsSelected.projector"
      :key="projectorKey + '-projector'"
      class="form-application__item"
    >
      <div class="form-application__item__col">
        <div class="form-application__item__image">
          {{ $t('form.itemsCount') }} <img
            :src="formatImageUrlPath(projector.img)"
            :alt="projector.title"
          >
        </div>
        <span
          class="form-application__item__text"
          v-text="projector.title"
        />
      </div>
      <div class="form-application__item__price">
        {{ formatPrice(projector.price) }}
        <span v-text="$t('common.dayNight')" />
      </div>
    </div>

    <div
      v-for="(lcd, lcdKey) in itemsSelected.lcd"
      :key="lcdKey + '-ledTv'"
      class="form-application__item"
    >
      <div class="form-application__item__col">
        <div class="form-application__item__image">
          {{ $t('form.itemsCount') }}
          <img
            :src="formatImageUrlPath(lcd.img)"
            :alt="lcd.title"
          >
        </div>
        <span
          class="form-application__item__text"
          v-text=" lcd.title"
        />
      </div>
      <div class="form-application__item__price">
        {{ formatPrice(lcd.price) }}
        <span v-text="$t('common.dayNight')" />
      </div>
    </div>

    <div
      v-for="(screen, screenKey) in itemsSelected.screen"
      v-show="itemsSelected.screen !== 'screen_none'"
      :key="screenKey + '-screen'"
      class="form-application__item"
    >
      <div class="form-application__item__col">
        <div class="form-application__item__image">
          {{ $t('form.itemsCount') }}
          <img
            :src="formatImageUrlPath(screen.img)"
            :alt="screen.title"
          >
        </div>
        <span class="form-application__item__text">
          {{ $t('form.itemsCount') }}
          {{ screen.title }}
        </span>
      </div>
      <span class="form-application__item__price">
        {{ formatPrice(screen.price) }}
        <span v-text="$t('common.dayNight')" />
      </span>
    </div>

    <div
      v-for="(item, itemKey) in itemsSelected.extra"
      v-show="itemsSelected.extra !== 'extra_none'"
      :key="itemKey + '-item'"
      class="form-application__item"
    >
      <div class="form-application__item__col">
        <div class="form-application__item__image">
          {{ $t('form.itemsCount') }}
          <img
            :src="formatImageUrlPath(item.img)"
            :alt="item.title"
          >
        </div>
        <span class="form-application__item__text">
          {{ $t('form.itemsCount') }}
          {{ item.title }}
        </span>
      </div>
      <div class="form-application__item__price">
        {{ formatPrice(item.price) }}
        <span v-text="$t('common.dayNight')" />
      </div>
    </div>

    <div
      v-for="(discountSet, discountSetKey) in itemsSelected.discountSet"
      :key="discountSetKey + '-discount'"
      class="form-application__item"
    >
      <div class="form-application__item__col">
        <div class="form-application__item__image">
          {{ $t('form.itemsCount') }}
          <img
            :src="formatImageUrlPath(discountSet.img)"
            :alt="discountSet.title"
          >
        </div>
        <span class="form-application__item__text">
          {{ discountSet.title }}
        </span>
      </div>
      <div class="form-application__item__price">
        {{ formatPrice(discountSet.price) }}
        <span v-text="$t('common.dayNight')" />
      </div>
    </div>

    <div
      v-if="form.delivery"
      class="form-application__item"
    >
      <div>
        {{ `${$t('form.delivery.title')} ${ form.city}` }}
      </div>
      <div class="form-application__item__price">
        {{ formatPrice(deliveryPrice) }}
      </div>
    </div>
    <div
      v-if="form.assembly"
      class="form-application__item"
    >
      <div v-text="$t('form.assembly.title')" />
      <div class="form-application__item__price">
        {{ formatPrice(assemblyPrice) }}
      </div>
    </div>
    <ul v-if="totalPriceResults.length">
      <li
        v-for="(row, rowKey) in totalPriceResults"
        :key="rowKey"
      >
        <div
          class="total-date"
          v-text="row.date"
        />
        <span
          class="total-date-price"
          v-text="formatPrice(row.price)"
        />
      </li>
    </ul>

    <div class="form-application__item__total">
      {{ $t('form.totalItemSum') + ' ' + formatPrice(totalPriceItems) }}
      <span v-text="$t('common.without_wat')" />
    </div>
    <div class="form-application__item__total">
      <b>{{ $t('form.totalSum') + ' ' + formatPrice(totalPrice) }}</b>
      <span v-text="$t('common.without_wat')" />
    </div>
  </div>
</template>

<script>
import { helper } from '../../mixins/helper';
export default {
  mixins: [ helper ],
  props: {
    itemsSelected: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: false,
      default: null,
    },
    totalPriceResults: {
      type: Array,
      required: true,
    },
    totalPriceItems: {
      type: Number,
      required: true,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
    deliveryPrice: {
      type: Number,
      required: true,
    },
    assemblyPrice: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
ul {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 10px 0;
  flex-wrap: wrap;
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .375rem .75rem;
    border-radius: .25rem;
    box-shadow: 0 3px 6px rgba(0,0,0,.16078);
    margin-left: 10px;

    .total-date {
      font-size: 10px;
    }
    span {
      font-size: 14px;
    }
  }
}
</style>
