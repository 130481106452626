<template>
  <div
    id="apply-form"
    class="apply"
  >
    <button
      class="apply__form btn btn-primary btn-lg"
      @click="showForm = true"
    >
      <span v-text="$t('common.next')" />
      <AppArrow
        class-name="next-arrows"
      />
    </button>

    <vodal
      :show="showForm"
      animation="door"
      :width="800"
      :height="850"
      class-name="main-modal"
      :class="{'modal-open': showForm}"
      @hide="showForm = false"
    >
      <Form
        v-if="showForm"
      >
        >
      </Form>
    </vodal>
  </div>
</template>

<script>
  import Vodal from 'vodal';
  import Form from '../Form';
  import AppArrow from './AppArrow';

  export default {
    components: { AppArrow, Form, Vodal },
    data() {
      return {
        loading: true,
        showForm: false,
        modal: [],
      };
    },
    watch: {
      showForm: function () {
        document.body.style.overflow = this.showForm ? 'hidden' : '';
      },
    },
  };

</script>

<style>
  .vodal-dialog {
    height: 95%!important;
  }
</style>
