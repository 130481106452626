<template>
  <div class="contacts-page">
    <section class="cover-1 text-center">
      <HeadBlock
        :header-title="$t('pages.contacts.head.title')"
        :menu-items="false"
      >
        <AppContacts />
      </HeadBlock>
    </section>
    <Map class="map" />
  </div>
</template>

<script>
import Vue from 'vue';
import HeadBlock from '../common/HeadBlock';
import { helper } from '../../mixins/helper.js';
import Map from '../../components/maps/map';
import AppContacts from '../../components/AppContacts';

export default Vue.extend({
  components: { AppContacts, Map, HeadBlock },
  mixins: [ helper ],

});
</script>

<style lang="scss" scoped>
.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  a {
    color: white;
  }
}
.map {
  height: 520px;
}
</style>
