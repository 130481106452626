<template>
  <div class="contacts">
    <ul class="menu-links">
      <li class="email">
        <a
          :href="'mailto:' + contacts.email"
          v-text="contacts.email"
        />
      </li>
      <li class="phone">
        <a
          :href="'tel:' + formatCallablePhoneNumber"
          v-text="contacts.phone"
        />
      </li>
      <li class="location">
        <a
          :href="contacts.addressGoogleUrl"
          target="_blank"
          v-text="contacts.address"
        />
      </li>
    </ul>
    <ul
      v-if="showSocialNetworks"
      class="social"
    >
      <li>
        <a
          :href="contacts.facebookLink"
          target="_blank"
          title="Facebook"
          class="fa fa-facebook"
        />
      </li>
      <li>
        <a
          :href="contacts.instaLink"
          target="_blank"
          title="Instagram"
          class="fa fa-instagram"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { helper } from '../mixins/helper.js';
export default {
  mixins: [ helper ],
  props: {
    showSocialNetworks: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

.menu-links {
  a {
    padding-left: 10px;
  }
}

</style>
