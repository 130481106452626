<template>
  <div id="el-1__second-step">
    <AppArrow
      class-name="next-arrows arrows-vertical mobile-only"
    />
    <div class="container">
      <div class="main-form__title">
        <span
          class="like-h2"
          v-text="$t('pages.extra.projectorsScreen.title')"
        />
      </div>
      <div class="row second-step">
        <div
          v-for="(screen, key) in projectorsExtraScreenList"
          :key="key"
          class="main-form__item col-md-5ths col-md-2"
          :class="{'active-item btn btn-primary btn-lg mb-2 mr-2 ml-2': isActive(screen), disabled: isDisabled(screen), 'none-class': isNone(screen) }"
        >
          <label>
            <input
              :id="screen.name"
              v-model="screensSelected"
              type="checkbox"
              :value="screen.name"
              class="hidden"
              @input="updateSelected"
            >
            <div
              v-if="screen.name !== none"
              class="main-form__item__content"
            >
              <span
                class="main-form__item__title"
                v-text="screen.title"
              />
              <div class="main-form__item__image">
                <img
                  :src="getImagePath(screen.img)"
                  :alt="screen.title"
                >
              </div>
              <div class="main-form__item__content__text">
                <p
                  class="description"
                  v-text="screen.description"
                />
                <ul>
                  <li
                    v-for="(shortInfo, shortInfoIndex) of screen.shortInfo"
                    :key="shortInfoIndex"
                    v-text="shortInfo"
                  />
                </ul>
              </div>
              <span
                v-if="!isActive(screen)"
                class="main-form__item__content__btn btn btn-primary"
                v-text="$t('common.select')"
              />
            </div>
            <div
              v-if="isNone(screen)"
              class="main-form__item__content close-btn"
              v-text="$t('common.unselectAll')"
            />
          </label>

          <div
            v-if="screen.price"
            class="price"
          >
            {{ formatPrice(screen.price) }}
            <span v-text="$t('common.without_wat')" />
          </div>
        </div>
      </div>
    </div>

    <transition
      name="fade"
      mode="out-in"
      @enter="enter"
    >
      <ExtraSelect
        v-show="screensSelected.length"
        :extra-items-list="projectorsExtraList"
      />
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import ExtraSelect from './ExtraSelect';
import { helper } from '../mixins/helper.js';
import { mapGetters } from 'vuex';
import AppArrow from './parts/AppArrow';

export default Vue.extend({
  components: { AppArrow, ExtraSelect },
  mixins: [ helper ],
  data() {
    return {
      none: 'screen_none',
      screensSelected: [],
      modal: [],
      formPreviousId: 'el-1__second-step',
    };
  },
  computed: {
    ...mapGetters([
      'projectorsExtraScreenList',
      'projectorsExtraList',
    ]),
    itemsSelected() {
      return this.$store.getters.selectedItems;
    },
  },
  watch: {
    screensSelected: function () {
      if (this.noneIsSelected()) {
        this.itemsSelected.screen = this.none;
        return;
      }
      let list = [];

      for (let value of this.screensSelected) {
        this.projectorsExtraScreenList.filter(obj => {
          if (obj.name === value) {
            return list.push(obj);
          }
          return false;
        });
      }
      this.$store.dispatch('addExtraScreenSelectedItems', list);
    },
  },
  methods: {
    getImagePath(img) {
      return this.formatImageUrlPath(img);
    },
    isActive(screen) {
      if (this.noneIsSelected()) {
        return screen.name === this.none;
      }
      return this.screensSelected.includes(screen.name);
    },
    isDisabled(screen) {
      if (screen.name === this.none) {
        return false;
      }
      return this.noneIsSelected();
    },

    isNone(screen) {
      return screen.name === this.none;
    },
    noneIsSelected() {
      return this.screensSelected.includes(this.none);
    },

    enter: function (el, done) {
      //https://vuejs.org/v2/guide/transitions.html
      done();
      this.scrollToElement(el.id);
    },
    openModal(key) {
      this.modal.push(key);
    },
    modalIsVisible(key) {
      return this.modal.includes(key);
    },
    closeModal(key) {
      this.modal.splice(this.modal.indexOf(key), 1);
    },
    updateSelected(e) {
      if (this.multipleSelectIsEnabled) {
        return;
      }
      this.screensSelected = [e.target.value];
    },
  },
});
</script>
