import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/pages/Home';
import Contacts from '../views/pages/Contacts';
import PageNotFound from '../views/pages/404';
import TvPage from '../views/pages/LedTv';
import ProjectorsPage from '../views/pages/Projectors';
import ProjectorsScreenPage from '../views/pages/ProjectorsScreens';
import DiscountsPage from '../views/pages/DiscountSet';
import SuccessPage from '../views/pages/Success';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      meta: {
        title: 'Projektoru un ekrānu noma (īre) ar piegādi Rīgā!',
        metaTags: [
          {
            name: 'description',
            content: 'Multimediju projektoru un ekrānu noma mājas kīno vakariem, svētkiem, prezentācijām. Prezentācijas aprīkojuma noma. Elastīgs darba laiks un nosacijumi',
          },
          {
            property: 'og:description',
            content: 'Multimediju projektoru un ekrānu noma mājas kīno vakariem, svētkiem, prezentācijām. Prezentācijas aprīkojuma noma. Elastīgs darba laiks un nosacijumi',
          },
          {
            property: 'og:title',
            content: 'Projektoru un ekrānu noma (īre) ar piegādi Rīgā!',
          },
          {
            property: 'og:title',
            content: 'https://www.projektoru-noma.lv',
          },
        ],
      },
    },
    {
      path: '/led-tv-ekrani',
      component: TvPage,
      meta: {
        title: 'LED smart TV ekrānu uz statīva noma (īre)',
        metaTags: [
          {
            name: 'description',
            content: 'LED Smart TV ekrānu uz statīva noma Rīga. No 50" līdz 75" modeļi. Labākā izvēle gaišos telpas apstākļos',
          },
          {
            property: 'og:description',
            content: 'LED Smart TV ekrānu uz statīva noma Rīga. No 50" līdz 75" modeļi. Labākā izvēle gaišos telpas apstākļos',
          },
          {
            property: 'og:title',
            content: 'LED smart TV ekrānu uz statīva noma (īre)',
          },
          {
            property: 'og:url',
            content: 'https://www.projektoru-noma.lv/led-tv-ekrani',
          },
        ],
      },
    },
    {
      path: '/projektori',
      component: ProjectorsPage,
      meta: {
        title: 'Multimēdiju, video, projektoru noma (īre) Rīgā',
        metaTags: [
          {
            name: 'description',
            content: 'Multimediju, video projektoru komplektu noma. Tikai četras kategorijas lai atvieglotu Jūsu izvēli.  Pieejami adapteri pieslēgšanai andrīz visa veida datoriem',
          },
          {
            property: 'og:description',
            content: 'Multimediju, video projektoru komplektu noma. Tikai četras kategorijas lai atvieglotu Jūsu izvēli.  Pieejami adapteri pieslēgšanai andrīz visa veida datoriem',
          },
          {
            property: 'og:title',
            content: 'Multimēdiju, video, projektoru noma (īre) Rīgā',
          },
          {
            property: 'og:url',
            content: 'https://www.projektoru-noma.lv/projektori',
          },
        ],
      },
    },
    {
      path: '/projektoru-ekrani',
      component: ProjectorsScreenPage,
      meta: {
        title: 'Projektoru ekrānu noma (īre) Rīgā',
        metaTags: [
          {
            name: 'description',
            content: 'Projektoru ekrānu uz statīva noma. Neticami viegla montāža. Viegli transportēt',
          },
          {
            property: 'og:description',
            content: 'Projektoru ekrānu uz statīva noma. Neticami viegla montāža. Viegli transportēt',
          },
          {
            property: 'og:title',
            content: 'Projektoru ekrānu noma (īre) Rīgā',
          },
          {
            property: 'og:url',
            content: 'https://www.projektoru-noma.lv/projektoru-ekrani',
          },
        ],
      },
    },
    {
      path: '/komplekti',
      component: DiscountsPage,
      meta: {
        title: 'Projektoru un ekrāno nomas (īres) komplekti ar iespējamo piegādi Rīgā!',
        metaTags: [
          {
            name: 'description',
            content: 'Multimediju, video projektoru un ekrānu komplektu noma. Bezmaksas piegāde Rīgā.  Bezmaksas konsultācijas',
          },
          {
            property: 'og:description',
            content: 'Multimediju, video projektoru un ekrānu komplektu noma. Bezmaksas piegāde Rīgā.  Bezmaksas konsultācijas.',
          },
          {
            property: 'og:title',
            content: 'Projektoru un ekrāno nomas (īres) komplekti ar iespējamo piegādi Rīgā!',
          },
          {
            property: 'og:url',
            content: 'https://www.projektoru-noma.lv/komplekti',
          },
        ],
      },
    },
    {
      path: '/success',
      component: SuccessPage,
      meta: {
        title: 'Paldies par pasūtijumu! | projektoru-noma.lv',
      },
    },
    {
      path: '/kontakti',
      component: Contacts,
      meta: {
        title: 'Kontakti | projektoru-noma.lv',
      },
    },
    {
      path: '*',
      component: PageNotFound,
      meta: {
        title: 'Projektoru un ekrānu noma (īre) ar piegādi Rīgā! TV ekrāni!',
      },
    },
  ],
});
