<template>
  <div class="container form">
    <FormListItems
      :items-selected="itemsSelected"
      :form="form"
      :total-price-results="totalPriceResults"
      :total-price-items="totalPriceItems"
      :total-price="totalPrice"
      :delivery-price="deliveryPrice"
      :assembly-price="assemblyPrice"
    />

    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
      class="form-application"
      method="post"
    >
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <div class="col">
          <div class="form-group">
            <h4
              class="form-group__title"
              v-text="$t('form.rent_application')"
            />
            <div class="form-group__switcher">
              <div
                class="btn"
                :class="{'btn-primary': isPrivateClientType, 'btn-secondary': !isPrivateClientType }"
                @click="setPrivateClientActive"
                v-text="$t('form.private_person')"
              />
              <div
                class="btn"
                :class="{'btn-primary': isLegalClientType, 'btn-secondary': !isLegalClientType }"
                @click="setLegalClientActive"
                v-text="$t('form.legal_person')"
              />
            </div>

            <div
              v-if="isLegalClientType"
              class="row"
            >
              <InputText
                v-model="form.registrationNumber"
                field="registrationNumber"
                class="col"
                :placeholder="$t('form.placeholder.registrationNr')"
                :validation-message="$t('form.validation.registrationNr')"
                :rules="'required'"
              />

              <InputText
                v-model="form.companyName"
                field="companyName"
                class="col"
                :placeholder="$t('form.placeholder.companyName')"
                :validation-message="$t('form.validation.companyName')"
                :rules="'required'"
              />
            </div>

            <div class="row">
              <InputText
                v-model="form.name"
                field="name"
                class="col"
                :placeholder="$t('form.placeholder.name')"
                :validation-message="$t('form.validation.name')"
                :rules="'required'"
              />

              <InputText
                v-model="form.surname"
                field="surname"
                class="col"
                :placeholder="$t('form.placeholder.surname')"
                :validation-message="$t('form.validation.surname')"
                :rules="'required'"
              />
            </div>

            <div class="row">
              <InputText
                v-model="form.phone"
                field="phone"
                class="col"
                :placeholder="$t('form.placeholder.phone')"
                :validation-message="$t('form.validation.phone')"
                :rules="'required|numeric'"
              />
              <InputText
                v-model="form.email"
                field="email"
                class="col"
                :placeholder="$t('form.placeholder.email')"
                :validation-message="$t('form.validation.email')"
                :rules="'required|email'"
              />
            </div>

            <div class="row">
              <InputDatepicker
                v-model="form.dateFrom"
                field="dateFrom"
                class="col date-from"
                :placeholder="$t('form.placeholder.date_from')"
                :validation-message="$t('form.validation.date_from')"
                :min-date="disabledDays.to"
                :rules="'required'"
                @input="dateFromUpdated"
              />
              <InputDatepicker
                v-model="form.dateTo"
                field="dateTo"
                class="col date-to"
                :placeholder="$t('form.placeholder.date_to')"
                :validation-message="$t('form.validation.date_to')"
                :min-date="disabledDaysTo.to"
                :rules="'required'"
              />
            </div>

            <div class="row">
              <InputText
                v-model="form.comment"
                field="comment"
                class="col"
                :placeholder="$t('form.placeholder.comment')"
              />
            </div>

            <div class="row">
              <div class="col form-check">
                <input
                  id="delivery"
                  v-model="form.delivery"
                  type="checkbox"
                  name="delivery"
                  value="yes"
                >
                <label
                  for="delivery"
                  v-text="$t('form.delivery_needed')"
                />
              </div>
            </div>

            <transition class="fade">
              <div v-if="form.delivery">
                <div class="row">
                  <div class="col">
                    <b-form-select
                      v-model="form.city"
                      :options="cityList"
                      required
                      oninvalid="this.setCustomValidity(this.$t('form.validation.city'))"
                    />
                  </div>
                  <InputText
                    v-model="form.address"
                    field="address"
                    class="col"
                    :placeholder="$t('form.placeholder.address')"
                    :validation-message="$t('form.validation.address')"
                    :rules="'required'"
                  />
                </div>
                <div class="row">
                  <div class="col form-check">
                    <input
                      id="assembly"
                      v-model="form.assembly"
                      type="checkbox"
                      name="assembly"
                      value="yes"
                    >
                    <label
                      for="assembly"
                      v-text="$t('form.assembly_needed')"
                    />
                  </div>
                </div>
              </div>
            </transition>

            <div
              v-if="settings.consentsEnabled"
              class="row consents"
            >
              <div class="form-check">
                <input
                  id="agree"
                  type="checkbox"
                  name="consent"
                  value="yes"
                  required
                >
                <label
                  for="agree"
                  v-html="consent"
                />
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="submit btn btn-primary mb-2"
          v-text="$t('form.book_now')"
        />
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
  import Vue from 'vue';
  import axios from 'axios';
  import { helper } from '../mixins/helper.js';
  import FormListItems from './parts/FormListItems';
  import InputText from './inputs/InputText';
  import InputDatepicker from './inputs/InputDatepicker';

  export default Vue.extend({
    components: { InputDatepicker, InputText, FormListItems },
    mixins: [ helper ],
    data() {
      return {
        itemsSelectedSum: 0,
        isSubmitted: false,
        render: true,
        errors: [],
        consent: this.$t('form.consents.agree'),
        deliveryInfoTrans: this.$t('form.delivery.default_message'),
        deliveryDiscountInfoTrans: this.$t('form.delivery.discounts_info'),
        form: {
          name: '',
          surname: '',
          phone: '',
          email: '',
          registrationNumber: '',
          companyName: '',
          delivery: false,
          assembly: false,
          dateFrom: null,
          dateTo: null,
          address: '',
          city: 'Rīga',
          clientType: 'private',
          comment: '',
          deliveryPrice: 0,
          assemblyPrice: 0,
        },
        disabledDays: {
          to: new Date(Date.now() - 8640000),
        },
        disabledDaysTo: {
          to: new Date(Date.now() - 8640000),
        },
        // eslint-disable-next-line no-undef
        appUrl: process.env.APP_URL,
      };
    },
    computed: {
      itemsSelected() {
        return this.$store.getters.selectedItems;
      },
      isLegalClientType() {
        return this.form.clientType === 'legal';
      },
      isPrivateClientType() {
        return this.form.clientType === 'private';
      },
      totalPriceResults() {
        let amountForAllItems = this.getSelectedItemsSum(this.itemsSelected);
        let amountForCurrentDay = null;
        let allPeriodPriceCalculation = [];

        if (this.form.dateFrom && this.form.dateTo) {
          const period = this.getDatesPeriod(this.form.dateFrom, this.form.dateTo);
          let i = 0;

          for (let date of period) {
            if (i === 0) {
              amountForCurrentDay = amountForAllItems;
            } else if (!this.isSunday(date)) {
              amountForCurrentDay = amountForAllItems / 2; // price 50% for each next day
            } else {
              amountForCurrentDay = 0; // sunday for free
            }

            allPeriodPriceCalculation.push({
              date: date.toDateString(),
              price: amountForCurrentDay,
            });
            i++;
          }
        }
        return allPeriodPriceCalculation;
      },
      totalPriceItems() {
        let amountForAllItems = this.getSelectedItemsSum(this.itemsSelected);
        if (this.totalPriceResults.length) {
          amountForAllItems = this.totalPriceResults.reduce((prev, cur) => {
            return prev + cur.price;
          }, 0);
        }
        return amountForAllItems;
      },
      totalPrice() {
        let priceTotal = this.totalPriceItems;
        if (this.deliveryPrice) {
          priceTotal = priceTotal + this.deliveryPrice;
        }
        if (this.assemblyPrice) {
          priceTotal = priceTotal + this.$store.getters.serviceList.assembly[0].price;
        }
        return priceTotal;
      },
      deliveryPrice() {
        return (this.form.delivery) ? this.$store.getters.serviceList.delivery[0].price : 0;
      },
      assemblyPrice() {
        return (this.form.assembly) ? this.$store.getters.serviceList.assembly[0].price : 0;
      },
    },
    methods: {
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
      onSubmit() {
        let projector = null;
        let screen = null;
        let tv = null;
        let extra = null;
        let discountSet = null;
        //collect data or submit
        let formData = new FormData();
        this.form.deliveryPrice = this.deliveryPrice;
        this.form.assemblyPrice = this.assemblyPrice;
        for (let field in this.form) {
          formData.append(field, this.form[field]);
        }

        if (typeof this.itemsSelected.discountSet != 'undefined') {
          discountSet = this.itemsSelected.discountSet;
        }
        if (typeof this.itemsSelected.extra != 'undefined') {
          extra = this.itemsSelected.extra;
        }
        if (typeof this.itemsSelected.lcd != 'undefined') {
          tv = this.itemsSelected.lcd;
        }
        if (typeof this.itemsSelected.screen != 'undefined') {
          screen = this.itemsSelected.screen;
        }
        if (typeof this.itemsSelected.projector != 'undefined') {
          projector = this.itemsSelected.projector;
        }

        const allItems = this.itemsSelected.concat(projector, screen, tv, extra, discountSet);
        formData.append('items', JSON.stringify(allItems));
        formData.append('orderPriceSum', this.totalPriceItems.toString());
        formData.append('orderSum', this.totalPrice.toString());
        formData.append('orderSumPeriod', JSON.stringify(this.totalPriceResults));
        //todo move headers to config
        axios.post(this.appUrl + '/API/Client.php', formData, {
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/form-data',
          },
        }).catch((e) => {
            console.error('error', e);
          });
        document.body.style.overflow = '';
        this.$router.push('success');
      },
      setPrivateClientActive() {
        this.form.clientType = 'private';
      },
      setLegalClientActive() {
        this.form.clientType = 'legal';
      },
      dateFromUpdated() {
        let nextDay = new Date(this.form.dateFrom);
        nextDay.setDate(new Date(this.form.dateFrom).getDate() + 1);
        this.disabledDaysTo.to = nextDay;
      },
      isSaturday(day) {
        const dt = new Date(day);
        return dt.getDay() === 6;
      },
      isSunday(day) {
        const dt = new Date(day);
        return dt.getDay() === 0;
      },
      getDatesPeriod(startDate, endDate) {
        let dates = [];
        const formatStartDate = new Date(startDate);
        const formatEndDate = new Date(endDate);

        for (let i = formatStartDate; i < formatEndDate; i.setDate(i.getDate()+1)) {
          dates.push(new Date(i));
        }

        return dates;
      },
    },
  });
</script>
