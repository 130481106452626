<template>
  <div id="third-step">
    <AppArrow
      class-name="next-arrows arrows-vertical mobile-only"
    />
    <div class="container">
      <div class="main-form__title">
        <span
          class="like-h2"
          v-text="$t('pages.extra.projectorsScreen.title')"
        />
      </div>
      <div class="row third-step">
        <div
          v-for="(item, key) in extraItemsList"
          :key="key"
          class="main-form__item col-sm"
          :class="{'active-item btn btn-primary btn-lg mb-2 mr-2 ml-2': isActive(item), disabled: isDisabled(item), 'none-class': isNone(item) }"
        >
          <input
            :id="item.name"
            v-model="extraItemsSelected"
            type="checkbox"
            :value="item.name"
            class="hidden"
            @input="updateSelected"
          >
          <label :for="item.name">
            <div
              v-if="item.name !== none"
              class="main-form__item__content"
            >
              <span
                class="main-form__item__title"
                v-text="item.title"
              />
              <div class="main-form__item__image">
                <img
                  :src="getImagePath(item.img)"
                  :alt="item.title"
                >
              </div>
              <div class="main-form__item__content__text">
                <p
                  class="description"
                  v-text="item.description"
                />
                <ul>
                  <li
                    v-for="(shortInfo, shortInfoIndex) of item.shortInfo"
                    :key="shortInfoIndex"
                    v-text="shortInfo"
                  />
                </ul>
              </div>
              <span
                v-if="!isActive(item)"
                class="main-form__item__content__btn btn btn-primary"
                v-text="$t('common.select')"
              />
            </div>
            <div
              v-if="isNone(item)"
              class="main-form__item__content close-btn"
              v-text="$t('common.unselectAll')"
            />
          </label>
          <div
            v-if="item.price"
            class="price"
          >
            {{ formatPrice(item.price) }}
            <span v-text="$t('common.without_wat')" />
          </div>
        </div>
      </div>
    </div>

    <transition
      name="fade"
      mode="out-in"
      @enter="enter"
    >
      <ApplyForm
        v-show="showApplyButton"
      />
    </transition>
  </div>
</template>

<script>

import Vue from 'vue';
import { helper } from '../mixins/helper.js';
import ApplyForm from './parts/ApplyForm';
import AppArrow from './parts/AppArrow';


export default Vue.extend({
  components: { AppArrow, ApplyForm },
  mixins: [ helper ],
  props: {
    extraItemsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      none: 'extra_none',
      extraItemsSelected: [],
      showApplyButton: false,
    };
  },
  computed: {
    itemsSelected() {
      return this.$store.getters.selectedItems;
    },
  },
  watch: {
    extraItemsSelected: function () {

      if (this.noneIsSelected()) {
        this.itemsSelected.extra = this.none;
        return this.showApplyButton = true;
      }

      if (!this.applyButtonState(this.extraItemsSelected)) {
        return this.showApplyButton = false;
      }

      let list = [];
      for (let value of this.extraItemsSelected) {
        this.extraItemsList.filter(obj => {
          if (obj.name === value) {
            return list.push(obj);
          }
          return false;
        });
      }
      this.$store.dispatch('addExtraSelectedItems', list);
      return this.showApplyButton = true;
    },
  },
  methods: {
    getImagePath(img) {
      return this.formatImageUrlPath(img);
    },
    isActive(item) {
      if (this.noneIsSelected()) {
        return item.name === this.none;
      }

      return this.extraItemsSelected.includes(item.name);
    },
    isDisabled(item) {
      if (item.name === this.none) {
        return false;
      }
      return this.noneIsSelected();
    },

    isNone(item) {
      return item.name === this.none;
    },
    noneIsSelected() {
      return this.extraItemsSelected.includes(this.none);
    },
    updateSelected(e) {
      if (this.multipleSelectIsEnabled) {
        return;
      }
      this.extraItemsSelected = [e.target.value];
    },

    enter: function (el, done) {
      //https://vuejs.org/v2/guide/transitions.html
      done();
      this.scrollToElement(el.id);
    },
  },
});
</script>
