<template>
  <div
    :class="className"
    @click="scrollEvent"
  >
    <div
      class="scroll-prompt"
      scroll-prompt=""
      ng-show="showPrompt"
      style="opacity: 1;"
    >
      <div class="scroll-prompt-arrow-container">
        <div class="scroll-prompt-arrow">
          <div />
        </div>
        <div class="scroll-prompt-arrow">
          <div />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { helper } from '../../mixins/helper';

export default {
  mixins: [ helper ],
  props: {
    className: {
      type: String,
      required: true,
    },
    scrollToId: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    scrollEvent() {
      if (typeof this.scrollToId != 'undefined' && this.scrollToId.length) {
        this.scrollToElement(this.scrollToId);
      }
    },
  },
};
</script>
