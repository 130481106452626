<template>
  <div class="form-page">
    <section
      v-if="!loading"
      class="cover-1 text-center"
    >
      <HeadBlock
        :header-title="$t('pages.discount.head.title')"
        :header-sub-title="$t('pages.discount.head.subtitle')"
      />
    </section>

    <transition name="fade">
      <main v-if="!loading">
        <div class="main-form">
          <div class="container">
            <div class="main-form__title">
              <span
                class="like-h2"
                v-text="$t('pages.discount.productTitle')"
              />
            </div>
            <div class="row second-step">
              <div
                v-for="(item, key) in discountList"
                :key="key"
                class="main-form__item col-md-5ths col-md-3"
                :class="{'active-item btn btn-primary btn-lg mb-2 mr-2 ml-2': isActive(item)}"
              >
                <label>
                  <input
                    :id="item.name"
                    v-model="discountSetSelected"
                    type="checkbox"
                    class="hidden"
                    :value="item.name"
                    @input="updateSelected"
                  >
                  <div
                    v-if="item.name !== none"
                    class="main-form__item__content"
                  >
                    <span
                      class="main-form__item__title"
                      v-text="item.title"
                    />
                    <div class="main-form__item__image">
                      <img
                        :src="getImagePath(item.img)"
                        :alt="item.title"
                      >
                    </div>
                    <div class="main-form__item__content__text">
                      <p
                        class="description"
                        v-text="item.description"
                      />
                      <ul>
                        <li
                          v-for="(shortInfo, shortInfoIndex) of item.shortInfo"
                          :key="shortInfoIndex"
                          v-text="shortInfo"
                        />
                      </ul>
                    </div>
                    <span
                      v-if="!isActive(item)"
                      class="main-form__item__content__btn btn btn-primary"
                      v-text="$t('common.select')"
                    />
                  </div>
                </label>

                <div v-if="item.modalEnabled">
                  <button
                    class="modalOpenBtn"
                    @click="openModal(key + '-' + item.name)"
                  >
                    <span class="info-icon" />
                    {{ $t('common.more_info') }}
                  </button>
                  <vodal
                    :show="modalIsVisible(key + '-' + item.name)"
                    animation="door"
                    :width="800"
                    :height="600"
                    @hide="closeModal(key + '-' + item.name)"
                  >
                    <div class="container modal-text">
                      <h2 v-text="item.title" />
                      <div class="col-md-8-center">
                        <b-carousel
                          id="carousel-no-animation"
                          style="text-shadow: 0 0 2px #000"
                          no-animation
                          indicators
                        >
                          <span
                            v-for="(imageSrc, imageSrcIndex) of item.imgGallery"
                            :key="imageSrcIndex"
                          >
                            <b-carousel-slide
                              :img-src="getImagePath(imageSrc)"
                            />
                          </span>
                        </b-carousel>
                      </div>
                      <ul>
                        <li
                          v-for="(keyPoint, keyPointIndex) of item.keyPoints"
                          :key="keyPointIndex"
                          v-text="keyPoint"
                        />
                      </ul>
                    </div>
                  </vodal>
                </div>

                <div
                  v-if="item.price"
                  class="price"
                >
                  {{ formatPrice(item.price) }}
                  <span v-text="$t('common.without_wat')" />
                </div>
              </div>
            </div>
            <transition
              name="fade"
              mode="out-in"
              @enter="enter"
            >
              <ApplyForm
                v-show="showApplyButton"
              />
            </transition>
          </div>
        </div>
      </main>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeadBlock from '../common/HeadBlock';
import {helper} from '../../mixins/helper.js';
import Vodal from 'vodal';
import ApplyForm from '../../components/parts/ApplyForm';

export default {
  components: { HeadBlock, ApplyForm, Vodal },
  mixins: [ helper ],
  data() {
    return {
      loading: true,
      modal: [],
      none: 'discount_none',
      discountSetSelected: [],
      showApplyButton: false,
      formPreviousId: 'el-1__second-step',
    };
  },
  computed: {
    ...mapGetters([
      'discountList',
    ]),
  },
  watch: {
    discountSetSelected: function () {
      let list = [];

      if (!this.applyButtonState(this.discountSetSelected)) {
        return this.showApplyButton = false;
      }

      // get all data from store
      for (let value of this.discountSetSelected) {
        this.discountList.filter(obj => {
          if (obj.name === value) {
            return list.push(obj);
          }
          return false;
        });
      }
      this.$store.dispatch('addDiscountSelectedItems', list);
      return this.showApplyButton = true;
    },
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    updateSelected(e) {
      if (this.multipleSelectIsEnabled) {
        return;
      }
      this.discountSetSelected = [e.target.value];
    },
    getImagePath(img) {
      return this.formatImageUrlPath(img);
    },
    isActive(item) {
      return this.discountSetSelected.includes(item.name);
    },
    noneIsSelected() {
      return this.discountSetSelected.includes(this.none);
    },
    openModal(key) {
      this.modal.push(key);
    },
    modalIsVisible(key) {
      return this.modal.includes(key);
    },
    closeModal(key) {
      this.modal.splice(this.modal.indexOf(key), 1);
    },
    enter: function (el, done) {
      //https://vuejs.org/v2/guide/transitions.html
      done();
      this.scrollToElement(el.id);
    },
  },
};
</script>
