<template>
  <div id="app">
    <router-view />
    <Footer />
  </div>
</template>

<script>
  import Footer from './views/common/Footer';
  export default  {
    components: { Footer },
  };
</script>

