import productsList from './productsList';

export default {
  state: () => ({
    productsList,
  }),
  getters: {
    projectorsList: state => {
      return state.productsList.projectorsList;
    },
    discountList: state => {
      return state.productsList.discountSetList;
    },
    ledTvList: state => {
      return state.productsList.tvList;
    },
    screenList: state => {
      return state.productsList.projectorsScreenList.filter((key) => {
        return key.name !== 'screen_none';
      });
    },
    tvExtraList: state => {
      return state.productsList.tvExtraItemsList;
    },
    projectorsExtraList: state => {
      return state.productsList.projectorsExtraItemsList;
    },
    projectorsExtraScreenList: state => {
      return state.productsList.projectorsScreenList;
    },
  },

};
