<template>
  <validation-provider
    v-slot="validationContext"
    :name="field"
    :rules="rules"
  >
    <b-form-group :id="field + '-group'">
      <b-form-input
        :id="field"
        v-model="value"
        :name="field"
        :placeholder="placeholder"
        :state="getValidationState(validationContext)"
        @input="inputEvent"
      />

      <b-form-invalid-feedback v-text="validationMessage" />
    </b-form-group>
  </validation-provider>
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
    validationMessage: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      value: '',
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    inputEvent(val) {
      this.$emit('input', val);
    },
  },
};
</script>
